.builder {
    position: absolute;
    height: 75vh;
    top: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0% 4% 1% 4%;
    overflow: auto;
}
.buider1{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buider{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: start;
}
.buider-Wall{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items:flex-start;
}

.builder h1 {
    font-size: 30px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.builder-header {
    position: relative;
    text-align: center;
    /* top: 78px; */
    /* bottom: -80px; */
    /* left: 362px; */
}

.builder-next {
    position: relative;
    /* top: -103px; */
    /* right: -359px; */
}


ul.start-build {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2%;
    /* top: 150px; */
}

.product-banner1 {
    /* margin-top: -35px !important; */
}

.product-banner1 img {
    /* margin-top: 39px; */
    height: 90vh !important;
}

.builder-h1 {
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.start-build-input {
    width: 100% !important;
    justify-content: end;
    margin: 1% 0% 0% 0%;
}



.builder-img {
    position: relative;
    /* bottom: 64vh; */
    /* left: 47px; */

}

.builder-img img {
    height: 250px;
    width: 100%;
}

.builder-li {
    font-size: 20px;
    /* margin-top: 11px; */
}

.builder-panal {
    /* top: -120vh; */
    /* width: 348px; */
    /* margin-left: 480px; */
    /* left: 146px; */
}

/* @media (min-width: 768px) and (max-width: 1024px){
.builder-header img {
    width: 70%;
    height: 145vh;
}
}

@media (max-width: 700px){
.builder-header img {
    width: 147%;
    height: 77vh;
    overflow: hidden;
}
} */