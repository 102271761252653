
.sub-menu-block {
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  animation: slideInFromRight 0.5s ease-in-out forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.project-builder-container {
  position: absolute;
  top: 40%;
  padding: 2%;
  margin: 2%;
}
.cards-project-builder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 1rem;
  width: 100%;
}

.card-head {
  border: 2px solid white;
  border-radius: 7px;
  padding: 2%;
  height: max-content;
}

.cards__item img {
  display: block;
  height: 150px;
  padding: 1%;
  border-radius: 7px;
  /* background: url(https://picsum.photos/200/300.jpg) center/cover; */
  background-color: rgb(0, 0, 0);
  transition: 2s;
  width: 100%;
}

.hover-container {
  display: none;
}

.hover-container-focus {
  display: block;
  height: 100%;
  width: 100%;
}

.cards__item h1 {
  justify-content: center;
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  top: 50%;
  bottom: 50%;
  right: 50%;
  left: 50%;
  white-space: nowrap;
}

.cards__item .spacetype-text-content {
  font-size: 12px;
  padding: 2%;
  margin-bottom: 2%;
}

.cube-volume {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cube-volume p {
  color: rgb(0, 0, 0);
  margin: 7px;
}

.cube-volume-row {
  margin-top: 1%;
}
.wall-ceiling-floor{
margin-top: 1%;
}
.col-rt{
  padding-left: 136px;
}
.deductible-row {
  margin-top: 5%;
}

.checkbox-door-window {
  background-color: white;
  padding: 8px;
  padding-top: 10px;
  margin: auto;
}

.checkbox-door-window .col {
  color: black;
}

.wall-ceiling-floor {
  margin-top: 5%;
}

.room-space-type-checkbox {
  margin: 1% 0%;
}

.room-space-type-checkbox .col-4,
.room-space-type-checkbox .col {
  color: black;
  background-color: white;
  margin: 10px;
  padding: 7px;
}

.project-scroll{
  position: absolute;
  top:30%;
  height: 90vh;
  overflow: auto;
}

.checkbox-project{
  /* padding: 0 0px 0 31px; */
  display: grid;
  place-items: center;
  /* height: 100vh; */
}


/* Media query for mobile devices (horizontal scrolling) */
@media (max-width: 2066px) {
  .scrollable-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Prevent line breaks */
    display: flex; /* Use flexbox for horizontal layout */
  }

  .card-head {
    flex: 0 0 300px; /* Set fixed width for each card */
    margin-right: 10px; /* Add spacing between cards */
  }
}


/* .deductibleDimensions{
padding: 25px;
} */