.mx-10{
  margin-left: 10px;
  margin-right: 10px;

}
.mt-2{
  margin-top:20px;
}
.mt-1{
  margin-top:10px;
}
.p-2{
  padding:20px;
}


#newHeader::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: #F5F5F5;
}

#root::-webkit-scrollbar-track {
width: 5px;
height: 5px;
background-color: #F5F5F5;
}
#newHeader::-webkit-scrollbar
{
width: 5px;
height: 5px;
background-color: #F5F5F5;
}

#newHeader::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: #a3a3a3;
}
.quantity-cart{
/* min-width: 175px; */
  display: table;
  padding-top: 10px;
}

.quantity-cart input {
 
  text-align: center;
  display: table-cell;
  width: 100%;
}

.quantity-cart  a {
  display: table-cell;
  vertical-align: middle;
  font-style: 18px;
  font-weight: 600;
color: #000;
padding-left: 10px;
  padding-right: 10px;
}

.invoice {
  background: #fff;
  padding: 20px
}

.invoice-company {
  font-size: 20px
}

.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px
}

.invoice-date,
.invoice-from,
.invoice-to {
  display: table-cell;
  width: 1%
}

.invoice-from,
.invoice-to {
  padding-right: 20px
}

.invoice-date .date,
.invoice-from strong,
.invoice-to strong {
  font-size: 16px;
  font-weight: 600
}

.invoice-date {
  text-align: right;
  padding-left: 20px
}

.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%
}

.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle
}

.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px
}

.invoice-price small {
  font-size: 12px;
  font-weight: 400;
  display: block
}

.invoice-price .invoice-price-row {
  display: table;
  float: left
}

.invoice-price .invoice-price-right {
  width: 25%;
  background: #2d353c;
  color: #fff;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 300
}

.invoice-price .invoice-price-right small {
  display: block;
  opacity: .6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px
}

.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px
}

.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%
}

.invoice>div:not(.invoice-footer) {
  margin-bottom: 20px
}

.btn.btn-white, .btn.btn-white.disabled, .btn.btn-white.disabled:focus, .btn.btn-white.disabled:hover, .btn.btn-white[disabled], .btn.btn-white[disabled]:focus, .btn.btn-white[disabled]:hover {
  color: #2d353c;
  background: #fff;
  border-color: #d9dfe3;
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}



.modal-body {
position:relative;
padding:0px;
}
.close {
position:absolute;
right:-30px;
top:0;
z-index:999;
font-size:2rem;
font-weight: normal;
color:#fff;
opacity:1;
}
body { overflow:inherit; padding-right:inherit !important; }

figure {
  width: 300px;
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}
.home-font{
   font-size:18px;
}

.float{
position:fixed;
/* width:60px;
height:60px; */
top:10px;
right:10px;
color:#FFF;
border-radius:50px;
text-align:center;
font-size:30px;
box-shadow: 2px 2px 3px #999;
z-index:100;
}

.row.no-gutters {
  display: flex;
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  width: 50%;
  padding-right: 0;
  padding-left: 0;
}
.px-2{
padding-left:20px;
padding-right:20px;

}
.p-2{
  padding:20px;
}

.disabledCursor { 
cursor: default;
}

.rank-label-container input {
  display: none;
}
.rank-label-container input >span {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.rank-label-container input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}



/* #ex2 img:hover { cursor: url(grab.cur), default; }
#ex2 img:active { cursor: url(grabbed.cur), default; } */

@media (max-width: 480px) {
 
  .invoice{
      padding:0px;
  }
  .invoice-header{
      padding:10px;
  }
  .invoice-date,
.invoice-from,
.invoice-to {
  display: contents;
  width: 1%
}

.invoice-date {
  text-align: left;
  padding-left: 20px;
}
.invoice-price .invoice-price-left .sub-price {
  display:block;
  vertical-align: middle;
  padding: 0 20px;
}
}

#loading-image {
  position: absolute; 
  top: 0; 
  bottom: 0; 
  left: 0; 
  right: 0; 
  margin: auto; 
  z-index: 9999;
}

.li-product-spl {
  height: fit-content;
	display: flex;
	margin-bottom: 30px;
	position: relative;
	background: #000;
	padding: 15px 15px;
	border-radius: 5px;
	justify-content: center !important;
	align-items: center !important;
}



/* .image-slide {
	height: 100%;
	width: 100%;
	transition: background-image .3s ease-in-out;
} */

.slide-arrow {
	color: #FF0084;
	cursor: pointer;
	font-size: 1rem;
	/* position: fixed;
	top: 50%; */
}

.slide-arrow.right {
	right: 0.5rem;
}

.slide-arrow.left {
	left: 0.5rem;
}
.pointer {cursor: pointer;}


button.process_pay_btn {
  background: #FF0084 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  color: #fff;
  padding: 10px 20px 10px 20px;
  /*position: relative;
  top: 50px;*/
}
button.flot_lf {
  float: right;
  margin-top: 40px;
}

.modal-div {
  position: absolute;
  z-index: 9999;
  float: right;
  width: 100%;
}

.modal-content-new {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 20%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  margin: auto;
  float: right;
  margin-top: 75px;
  margin-right: 27px;
}

.top-al {
  margin-top:50px !important;
  margin-right: 0px !important;

  float: right;
}
.top-al-cart {
  margin-top: 0px !important;
  margin-right: 20px !important;
  float: right;
}
.border-select{
  border-radius: 10px;
}

#hero-register {
  width: 100%;
  /* height: 100vh; */
  /* background: 'url(assets/img/hero-bg.jpg) top center'; */
  background-size: cover;
  position: relative;
}
div#register {
  width: 30em;
  margin: auto;
  /* height: 100vh; */
}

.row .productcontent{
    margin-right: 0px !important;
    margin-left: -0px !important;
}
.strickOut{
  text-decoration: line-through;
}
.disable{
  background-color: rgba(184, 182, 182, 0.671) !important;
  cursor:not-allowed;
}