


@media (min-width: 700px) and (max-width: 767px){ 
.login-modal-new {
    margin-top: 36vh !important;
}

}



@media (min-width: 768px) and (max-width: 1024px)
{
  .login-modal-new {
    margin-top: 11vh !important ;
}
}

@media (min-width: 1024px) and (max-width: 1350px)
{
    .login-modal-new {
        margin-top: 10vh !important ;

    }
}