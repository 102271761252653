.banner-section {
    /* background: url(./img/SubSolutionBanner.webp); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-section h1 {
    text-shadow: 1px 1px 2px black, 0 0 1em rgb(0, 0, 0), 0 0 0.2em rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.banner-bottom-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-bottom-card-sub {
    background: #008aff;
    color: #ffffff;
    border: 2px solid #fcfcfc;
    padding: 25px 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 25px;
    display: flex;
    align-items: center;
    width: max-content;
    position: relative;
    bottom: 35px;
    gap: 15px;
}

.banner-bottom-card-sub img {
    width: 30px;
    height: max-content;
}

.banner-bottom-card-sub h6 {
    font-weight: 600;
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
}

article p {
    text-align: center;
}

article span {
    text-align: center;
    font-weight: 600;
}

.card-price {
    margin-top: 16px;
    gap: 10px;
}

.old-price {
    color: #969696;
    text-decoration: line-through;
    font-size: 16px;
    margin-right: 10px;
}

.sub-solution-card-group {
    margin: 40px 0;
}

.sub-solution-card-group col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-area p {
    text-align: left;
    font-size: 12.5px;
    font-weight: 400;
}

.content-area h6 {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #ff0084;
}

.input-areas form {
    display: grid;
}

.input-areas label {
    font-size: 14px;
}

.select-area label {
    font-size: 14px;
}


input[type] {
    padding: 9px 10px;
    line-height: 1.35;
    height: auto;
    border: 1px solid #cbcbcb;
}

/* input[type]::placeholder {
    padding: 9px 10px;
    line-height: 1.35;
    height: auto;
    border: 1px solid #cbcbcb;
} */

.select-area {
    margin-top: 30px;
    display: grid;
}

.button-subsolution {
    margin-top: 30px;

    .button-child {
        width: 100%;
    }
}

.select-area select {
    height: 42px;
    /* -webkit-appearance: none; */
}

.sub-solution-main section {
    width: 95%;
}

.sub-solution-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
}

.head-acoustical-coverage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 1.5%;
    width: 100%;
    word-wrap: break-word;
    font-size: 28px;

    .acoustical-coverage {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        color: black;
        width: 100%;
        padding: 2% 2%;
    }

}
.here-diff-container{
    height: 550px;
    overflow: auto;
}

.image-paragraph-container {
    font-size: 14px;
}

.categories-solution-head h5 {
    font-size: 16px;
    color: #ff0084;
    padding-bottom: 4%;
    border-bottom: 1px solid #ff0084;
}

.categories-text-link {
    font-size: 14px;
    color: #ffffff;
}

.categories-text-link:hover {
    font-size: 14px;
    color: #008aff;
}

.featured-products-text {
    background-color: transparent;
    color: white;
    padding: 5px;
    margin-bottom: 1px;
}


.featured-products-image-text:hover{

    .featured-products-text{
        background-color: transparent;
        color: #008aff;
        padding: 5px;
    }
    .card-feature{
        border: 1px solid #008aff;
        /* -webkit-transition: box-shadow .6s ease-out; */
        box-shadow: .8px .9px 8px #008aff;
    }
}

.select-options-btn {
    width: 100%;
    background-color: black;
    color: white;
    border: 1px solid white;
}

.select-options-btn:hover {
    width: 100%;
    background-color: black;
    color: #ff0084;
    border: 1px solid #ff0084;
}
.card-feature {
    border: 0.5px solid #ffffff;
}
.assessment-border {
    border: 1px solid #ff0084;
}

.assessment-border:hover {
    border: 1px solid #ff0084;
    -webkit-transition: box-shadow .6s ease-out;
    box-shadow: .8px .9px 8px #ff0084;
}

.sub-solution-main-child{
    display: flex;
    justify-content: center;
    width: 100%;
}


@media (min-width: 768px) {
    .sub-menu-img a {
        position: relative;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: transform 0.3s, box-shadow 0.3s;
    }

    .sub-menu-img a:hover img {
        transform: scale(1);
        box-shadow: -5px 0 10px rgba(0, 0, 0, 0.5),
            5px 0 10px rgba(0, 0, 0, 0.5),
            0 -5px 10px rgba(0, 0, 0, 0.5),
            0 5px 10px rgba(0, 0, 0, 0.5);
    }

    .sub-menu-img a::before,
    .sub-menu-img a::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: transform 0.7s;
        pointer-events: none;
    }

    .sub-menu-img a::before {
        top: 100%;
        left: 0;
        transform: scaleY(0);
    }

    .sub-menu-img a::after {
        bottom: 10%;
        right: 0;
        transform: scaleY(0);
    }

    .sub-menu-img a:hover::before,
    .sub-menu-img a:hover::after {
        transform: scaleY(1);
    }
}

@media (max-width:1000px) {
    .hide-content{
        display: none;
    }
    .sub-solution-main{
        width: 100%;
    }
}


/* .. */

.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  
  
  
  .sidebar .closebtn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    cursor: pointer;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: 2px solid #008aff;
    position: fixed;
    padding: 4px;
    bottom: 1%;
    left: 1%;
    border-radius: 50%;
  }
  
  .openbtn:hover {
    background-color: #ffffff;
    border: 2px solid #ff0084;
    transition: 0.5s;
    transform: rotate(360deg);
  }
  
  #main-sideBar {
    transition: margin-left .5s;
    padding: 16px;
    position: absolute;
    bottom: 1%;
    left: 1%;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 25px;}
    .sidebar a {font-size: 18px;}
  }